<template>
  <div v-if="isShowMainHeader" class="header-container">
    <HeaderBand
      v-if="isHeaderBandDisplayed && headerBand"
      class="header-band"
      :band="headerBand"
      @close="rootStore.setIsHeaderBandDisplayed(false)"
    />
    <div class="header" :class="{ 'light-mode': isLightBackground }">
      <div class="left">
        <NuxtLink to="/">
          <Icon
            :name="isLightBackground ? 'lmdv-logo-white' : 'lmdv-logo'"
            class="logo"
            alt="Logo"
            width="100px"
            height="50px"
            role="button"
            aria-label="home-button"
            :fill="isLightBackground ? 'var(--color-white)' : 'var(--color-green)'"
        /></NuxtLink>
        <nav>
          <div
            v-for="{ title, cssClass, dropdown } of desktopDataMenus"
            :key="title"
            class="menu"
            :class="cssClass"
            @mouseenter="isMenuOpened = true"
          >
            <div class="title">{{ title }}</div>
            <Icon
              class="icon"
              name="arrow-dropdown"
              :stroke="isLightBackground ? 'white' : undefined"
              width="22px"
              height="9px"
            />

            <!-- The 'dropdown-hitbox' is the area that can be hovered to trigger the dropdown.
                 It is just a bit bigger than the dropdown itself, to include the space between it
                 and the header, and also for quality of life so that the dropdown doesn't disappear
                 as soon as the mouse leaves the dropdown. -->
            <div v-if="dropdown" class="dropdown-hitbox" :class="{ close: !isMenuOpened }">
              <div class="dropdown">
                <!-- eslint-disable vue/no-v-html -->
                <div
                  v-if="dropdown.phrase && dropdown.component !== 'destinations'"
                  class="catch-phrase"
                  v-html="DOMPurify.sanitize(dropdown.phrase)"
                />
                <!-- eslint-enable vue/no-v-html -->

                <!-- By having the dropdown element contained in the menu, we can trigger it using
                 only CSS by using :hover on the menu. -->
                <component
                  :is="DROPDOWN_COMPONENTS[dropdown.component]"
                  v-if="dropdown.data.items"
                  :data="dropdown.data"
                  @item-click="isMenuOpened = false"
                />
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div class="right">
        <NuxtLink class="quotation" :href="useQuoteLink()">Demander un devis</NuxtLink>

        <NuxtLink class="phone" href="#agency-info">
          <span class="bubble-info">
            Nos spécialistes sont à votre écoute du lundi au samedi de 10h à 19h
          </span>

          <Icon
            class="icon"
            name="phone-outline"
            :stroke="isLightBackground ? 'white' : undefined"
          />
        </NuxtLink>
        <div class="calendar-appointment">
          <span class="bubble-info"> Prendre un rendez-vous </span>
          <Icon
            class="profile icon"
            name="calendar-appointment"
            :stroke="isLightBackground ? 'white' : undefined"
            @click="isAppointmentModalOpen = !isAppointmentModalOpen"
          />
        </div>
        <Icon
          class="search icon"
          name="search"
          :stroke="isLightBackground ? 'white' : undefined"
          @click="handleSearchClick()"
        />
        <Icon
          v-if="data.filters"
          class="filter"
          name="filter"
          fill="var(--color-black)"
          :stroke="isLightBackground ? 'white' : undefined"
        />
      </div>
    </div>
    <div class="header-mobile">
      <HeaderBand
        v-if="isHeaderBandDisplayed && headerBand"
        class="header-band-mobile"
        :band="headerBand"
        @close="rootStore.setIsHeaderBandDisplayed(false)"
      />

      <div class="header-content-mobile" :class="{ 'light-mode': isLightBackground }">
        <div class="left">
          <Icon
            :name="isLightBackground ? 'lmdv-logo-white-sm' : 'lmdv-logo-sm'"
            class="logo"
            alt="Logo"
            width="80px"
            height="40px"
            role="button"
            :fill="isLightBackground ? 'var(--color-white)' : 'var(--color-green)'"
            aria-label="home-button"
            @click="navigateTo('/')"
          />
        </div>

        <div class="right">
          <a href="#agency-info" height="4px" width="24px">
            <Icon
              class="right-icon"
              :stroke="isLightBackground ? 'var(--color-white)' : 'var(--color-gold)'"
              name="phone-outline"
              height="24px"
              width="24px"
          /></a>

          <Icon
            class="profile icon"
            name="calendar-appointment"
            :stroke="isLightBackground ? 'var(--color-white)' : 'var(--color-gold)'"
            @click="isAppointmentModalOpen = !isAppointmentModalOpen"
          />

          <Icon
            class="right-icon"
            :stroke="isLightBackground ? 'var(--color-white)' : 'var(--color-gold)'"
            name="search"
            height="24px"
            width="24px"
            @click="handleSearchClick()"
          />
          <Icon
            class="right-icon"
            :stroke="isLightBackground ? 'var(--color-white)' : 'var(--color-gold)'"
            name="burger-menu"
            height="24px"
            width="24px"
            aria-label="open-mobile-dropdown"
            @click="isDropdownMobileOpen = !isDropdownMobileOpen"
          />
        </div>
      </div>

      <nav v-if="isDropdownMobileOpen" class="dropdown-mobile">
        <div class="top-banner">
          <Icon
            aria-label="home-button"
            name="lmdv-logo"
            class="logo"
            alt="Logo"
            width="100px"
            fill="var(--color-green)"
            height="50px"
            @click="
              navigateTo('/');
              isDropdownMobileOpen = false;
            "
          />
          <button
            class="close-button"
            aria-label="close-mobile-menu"
            @click="isDropdownMobileOpen = false"
          >
            <Icon name="burger-menu" width="24px" height="24px" />
          </button>
        </div>
        <div class="menu-mobile">
          <HeaderDropdownMobileRoot
            v-if="isMenuMobileRootOpen && !currentMobileMenuComponent"
            :menus="mobileDataMenus"
            :socials="socials"
            @handle-menu-mobile-open="handleMenuMobileClick"
            @close="isDropdownMobileOpen = !isDropdownMobileOpen"
          />

          <HeaderDropdownMobileList
            v-if="currentMobileMenuComponent && currentMobileMenuComponent?.length > 0"
            :current-component-menu="currentMobileMenuObject"
            @back-to-root="
              currentMobileMenuComponent = undefined;
              isMenuMobileRootOpen = true;
            "
            @close-dropdown="isDropdownMobileOpen = false"
          />
        </div>
        <div v-if="isShowBottomMenu" class="bottom-menu">
          <NuxtLink
            class="quote-button"
            :href="useQuoteLink()"
            @click="isDropdownMobileOpen = false"
          >
            <Icon name="pen" fill="white" height="24px" width="24px" />
            Demander un devis
          </NuxtLink>
          <button class="contact-button" @click="isBottomSliderOpen = true">
            <Icon name="phone-outline" stroke="var(--color-green)" height="24px" width="24px" />
            Nous contacter
          </button>
          <div class="socials">
            <div class="icons">
              <NuxtLink
                v-for="social in socials"
                :key="social.name"
                :href="social.url"
                target="_blank"
              >
                <Icon :name="social.icon" fill="var(--color-green)" width="40px" height="40px" />
              </NuxtLink>
            </div>
          </div>
        </div>

        <div v-if="isBottomSliderOpen" class="bottom-slider">
          <div
            class="slider-item"
            role="button"
            aria-label="contact-referent-button"
            @click="handleMenuMobileClick(HEADER_MENUS_ENUM.CONTACT_REFERENT)"
          >
            <Icon name="phone-outline" width="26px" height="26px" stroke="var(--color-black)" />
            <span class="name">Contacter un conseiller</span>
            <Icon
              class="chevron"
              name="chevron"
              width="13px"
              height="13px"
              stroke="var(--color-black)"
            />
          </div>
          <div class="slider-item">
            <Icon name="profile" width="26px" height="26px" stroke="var(--color-black)" />
            <span class="name">Prendre un rendez-vous</span>
            <Icon
              class="chevron"
              name="chevron"
              width="13px"
              height="13px"
              stroke="var(--color-black)"
            />
          </div>
        </div>
        <div
          v-if="isBottomSliderOpen"
          class="slider-opacity"
          @click="isBottomSliderOpen = false"
        ></div>
      </nav>
    </div>
  </div>

  <FiltersModalAppointmentModal
    :is-open="isAppointmentModalOpen"
    @close="isAppointmentModalOpen = false"
  />

  <SearchModal
    :is-open="isSearchModalOpen"
    :options="[]"
    :trend-countries="trendCountries"
    @close="isSearchModalOpen = false"
  />
</template>

<script lang="ts" setup>
import DOMPurify from '@/lib/DOMPurify';

import { HeaderBand as Band } from '@/lib/strapi-types/components/HeaderBand';
import { Social } from '@/lib/strapi-types/components/Social';
import { Country } from '@/lib/strapi-types/Country';

import HeaderDropdownDestinations from '@/components/Header/Dropdown/HeaderDropdownDestinations.vue';
import HeaderDropdownImageItems from '@/components/Header/Dropdown/HeaderDropdownImageItems.vue';
import HeaderDropdownWhenWhereToGo from '@/components/Header/Dropdown/HeaderDropdownWhenWhereToGo.vue';
import { useHeaderEvent } from '@/composables/useHeaderEvent';

import HEADER_MENUS_ENUM from '@/lib/types/enums/headerMenus.enum';
import { DropdownModel, HeaderModel } from '@/lib/types/models/header';

const { data } = defineProps<{
  data: HeaderModel;
  socials: Social[];
  headerBand?: Band;
  trendCountries?: Country[];
}>();

const { setHeaderEventPayload } = useHeaderEvent();

const emitEvent = () => {
  setHeaderEventPayload({ openSearchModal: true });
};

const isAppointmentModalOpen = ref(false);
const isSearchModalOpen = ref(false);
const isMenuOpened = ref(false);
const windowStore = useWindow();
const route = useRoute();

const isLightBackground = computed(() => {
  return windowStore.scroll < 20 && isLightBackgroundLink(route.fullPath);
});

const rootStore = useRootStore();
const { isHeaderBandDisplayed, isShowMainHeader } = storeToRefs(rootStore);

interface DropdownComponents {
  [key: string]: any;
}

const DROPDOWN_COMPONENTS: DropdownComponents = {
  destinations: HeaderDropdownDestinations,
  'ou-et-quand-partir': HeaderDropdownWhenWhereToGo,
  inspirations: HeaderDropdownImageItems,
  whoAreWe: HeaderDropdownImageItems,
  cultureAndTravel: HeaderDropdownImageItems,
  travels: HeaderDropdownImageItems,
  refsAndTestimonials: HeaderDropdownImageItems
};

const desktopDataMenus = computed(() =>
  data.menus.filter(
    m =>
      m.dropdown.component !== HEADER_MENUS_ENUM.SPECIAL_OFFERS &&
      m.dropdown.component !== HEADER_MENUS_ENUM.CONTACT_REFERENT
  )
);
const mobileMenuToHide = [HEADER_MENUS_ENUM.CONTACT_REFERENT, HEADER_MENUS_ENUM.SPECIAL_OFFERS];

const mobileDataMenus = computed(() =>
  data.menus.filter(menu => !mobileMenuToHide.includes(menu.dropdown.component))
);

const isMenuMobileRootOpen = ref(true);

const isShowBottomMenu = computed(
  () => currentMobileMenuComponent.value !== HEADER_MENUS_ENUM.CONTACT_REFERENT
);

const currentMobileMenuComponent = ref<DropdownModel['component']>();

const currentMobileMenuObject = computed(() =>
  data.menus.find(m => m.dropdown.component === currentMobileMenuComponent.value)
);

const isBottomSliderOpen = ref(false);

function handleMenuMobileClick(component: DropdownModel['component']): void {
  currentMobileMenuComponent.value = component;
  isBottomSliderOpen.value = false;
}

const isDropdownMobileOpen = ref(false);

const handleSearchClick = () => {
  if (route.path === '/') {
    emitEvent();
  } else {
    isSearchModalOpen.value = true;
  }
};
</script>

<style lang="scss" scoped>
@use 'sass:color';
@use '$/border-radius.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/mouse-behavior.scss';
@use '$/shadows.scss';
@use '$/animation.scss';

.header-container {
  position: fixed;
  z-index: 1500;
  flex-direction: column;
  width: 100%;

  .header-band {
    display: flex;
  }

  .header-band-mobile {
    display: none;
  }

  .header-mobile {
    display: none;
  }

  .header {
    position: relative;
    z-index: 30;

    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;

    height: 60px;
    padding: 5px 20px;

    background-color: white;
    box-shadow: shadows.$menu;

    &.light-mode {
      background-color: transparent;

      .left {
        .menu {
          @include mouse-behavior.hoverable-svg(stroke, $color-base: white, $path: '.icon');

          color: white;
        }

        nav {
          .menu {
            @include mouse-behavior.hoverable-svg(
              stroke,
              $color-base: white,
              $color-hover: white,
              $path: '.icon'
            );

            &:hover {
              .title {
                cursor: pointer;
                color: colors.$white;
              }
            }
          }
        }
      }

      .right {
        .quotation {
          @include button.outlined(
            white,
            $size: small,
            $color-background: transparent,
            $color-hover: colors.$green
          );
        }
      }
    }

    .left {
      .icon {
        cursor: pointer;
      }

      nav {
        display: flex;
        gap: 45px;
        margin-left: 30px;

        .menu {
          @include mouse-behavior.hoverable-svg(stroke, $path: '.icon');

          cursor: pointer;
          align-items: center;

          &:hover {
            .title {
              cursor: pointer;
              color: colors.$green;
            }

            .dropdown-hitbox {
              display: flex;

              :deep(.destination) {
                @include mouse-behavior.clickable-alpha($color: colors.$green, $alpha: 0.8);
              }
            }
          }

          .title {
            font-weight: 600;
            white-space: pre;
            transition: color mouse-behavior.$duration-transition;
          }

          .icon {
            cursor: pointer;
            margin-top: 2px;
            margin-left: 12px;
          }

          .dropdown-hitbox {
            cursor: initial;

            position: absolute;
            z-index: 100;
            top: 50px;
            left: 0;
            transform: translateX(-29px); // old : doesn't exist

            display: none;

            width: 100%;
            max-width: 1300px; // old: max-width: 1100px;
            padding: 20px 50px;

            &:hover {
              display: flex;
            }

            .dropdown {
              position: relative;
              z-index: 100;

              flex-direction: column;
              gap: 40px;
              align-items: center;

              width: 100%;
              padding: 22px 28px;

              background-color: white;
              box-shadow: shadows.$menu;

              .catch-phrase {
                display: flex;
                gap: 3px;
                align-items: center;
                justify-content: center;

                width: 100%;

                font-size: 20px;
                font-weight: 600;
                color: colors.$black;
                text-transform: uppercase;

                // eslint-disable-next-line vue-scoped-css/no-unused-selector
                :deep(.highlight) {
                  color: colors.$green;
                }
              }
            }

            &.close {
              display: none !important;
            }
          }
        }
      }
    }

    .right {
      gap: 20px;

      .phone {
        position: relative;
        display: flex;
        align-items: center;

        .bubble-info {
          display: none;
        }

        &:hover {
          .bubble-info {
            position: relative;
            position: absolute;
            z-index: 1;
            top: -5px;
            right: 40px;

            display: block;

            width: 200px;
            padding: 10px 20px;

            font-weight: 600;
            color: colors.$white;

            background: colors.$green;
            border-radius: 4px;

            &::after {
              content: '';

              position: absolute;
              top: 25%;
              left: 100%;
              transform: translateY(-50%);

              border: 10px solid colors.$green;
              border-color: transparent transparent transparent colors.$green;
            }
          }
        }
      }

      .calendar-appointment {
        .bubble-info {
          display: none;
        }

        &:hover {
          .bubble-info {
            position: relative;
            position: absolute;
            z-index: 1;
            top: 100%;
            right: 25px;

            display: block;

            // width: 200px;
            padding: 10px 20px;

            font-weight: 600;
            color: colors.$white;

            background: colors.$green;
            border-radius: 4px;

            &::after {
              content: '';

              position: absolute;
              top: 0%;
              left: 70%;
              transform: translateY(-100%);

              border: 10px solid colors.$green;
              border-color: transparent transparent colors.$green transparent;
            }
          }
        }
      }

      .icon {
        cursor: pointer;
      }

      .quotation {
        @include button.outlined($size: small, $color-hover: colors.$green);

        white-space: pre;
      }

      .right-icon {
        cursor: pointer;
      }
    }
  }
}

@media (width <= 1460px) {
  .full-screen-only {
    display: none;
  }
}

@media (width <= 1024px) or (height <= 450px) {
  .header-container {
    .header-band {
      display: none;
    }

    .header-band-mobile {
      display: flex;
    }

    .header {
      display: none;
    }

    .header-mobile {
      position: fixed;
      z-index: 50;

      display: flex;
      flex-direction: column;

      width: 100%;

      box-shadow: 0 4px 8px rgb(170 170 170 / 25%);

      .dropdown-mobile {
        position: absolute;
        top: 0;

        flex-direction: column;

        width: 100%;
        height: 100vh;

        @supports (height: 100dvh) {
          height: 100dvh;
        }

        background-color: white;

        .bottom-menu {
          flex-direction: column;
          gap: 16px;

          width: 100%;
          padding: 16px;

          background-color: #f6f6f6;

          .quote-button {
            @include button.plain($size: medium, $color: colors.$green);

            gap: 8px;
          }

          .contact-button {
            @include button.outlined($size: medium);

            gap: 8px;
          }
        }

        .socials {
          justify-content: center;

          .icons {
            gap: 16px;
          }
        }

        .bottom-slider {
          @include animation.slide-in($duration: 0.3s);

          position: absolute;
          z-index: 1;
          bottom: 0;

          flex-direction: column;

          width: 100%;
          padding: 10px 0;

          background-color: white;
          border-top-left-radius: border-radius.$default;
          border-top-right-radius: border-radius.$default;

          .slider-item {
            cursor: pointer;

            gap: 10px;
            align-items: center;

            width: 100%;
            padding: 24px;

            &:first-child {
              border-bottom: solid 0.5px colors.$black;
            }

            .name {
              font-weight: 600;
            }

            .chevron {
              transform: rotate(-90deg);
              margin-left: auto;
            }
          }
        }

        .slider-opacity {
          position: absolute;
          width: 100%;
          height: 100vh;
          background-color: rgb(0 0 0 / 25%);
        }

        .top-banner {
          justify-content: space-between;
          width: 100%;
          padding: 8px 16px;
        }

        .menu-mobile {
          overflow-y: auto;
          flex-direction: column;

          height: 100vh;
          margin-top: 18px;
          padding: 0 24px;
        }
      }

      .header-content-mobile {
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: 49px;
        padding: 0 16px;

        background-color: white;

        &.light-mode {
          background-color: transparent;
        }
      }

      .left {
        .logo {
          cursor: pointer;
          width: 75px;
        }
      }

      .left,
      .right {
        gap: 10px;
        align-items: center;
        justify-content: center;

        .icon {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
