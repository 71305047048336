<template>
  <div
    class="trustpilot-widget"
    data-locale="fr-FR"
    data-template-id="53aa8912dec7e10d38f59f36"
    data-businessunit-id="5d1647d44d01c600010efeda"
    data-style-height="140px"
    data-style-width="100%"
    data-stars="4,5"
    data-review-languages="fr"
    data-font-family="Source Sans Pro"
  >
    <a href="https://fr.trustpilot.com/review/maisonsduvoyage.com" target="_blank" rel="noopener"
      >Trustpilot</a
    >
  </div>
</template>
<script lang="ts" setup>
useHead({
  script: [
    {
      src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
      async: true
    }
  ]
});
</script>
