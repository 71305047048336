import { BusinessHeader } from '@/lib/strapi-types/BusinessHeader';
import { Header } from '@/lib/strapi-types/Header';

import isBusinessLink from '@/utils/isBusinessLink';

import DROPDOWN_MENU_TYPES_ENUM from '@/lib/types/enums/dropdownEntryTypes.enum';
import HEADER_MENUS_ENUM from '@/lib/types/enums/headerMenus.enum';
// import URLS_ENUM from '@/lib/types/enums/urls.enum';
import WpContinent from '@/lib/types/enums/wpContinents';
import { DropdownDataByContinent, DropdownEntry, HeaderModel } from '@/lib/types/models/header';

export default function useHeaderData({
  header,
  businessHeader
}: {
  header: Partial<Header['attributes']>;
  businessHeader: Partial<BusinessHeader['attributes']>;
}): HeaderModel {
  const route = useRoute();
  const isBusinessHeader = isBusinessLink(route.fullPath);
  const destinations: DropdownDataByContinent = {
    items:
      header.destinationMenu?.destinations?.map(destination => ({
        continent: {
          type: DROPDOWN_MENU_TYPES_ENUM.CATEGORY,
          name: destination.title,
          path: `/${destination.slug}`
        } as DropdownEntry,
        countries: destination.links.map(
          country =>
            ({
              parent: { code: destination.title, name: destination.title },
              type: DROPDOWN_MENU_TYPES_ENUM.DESTINATION,
              name: country.name,
              path: `/${country.url}`
            }) as DropdownEntry
        ),
        parent: { code: HEADER_MENUS_ENUM.DESTINATION, name: 'Destinations' }
      })) ?? []
  };

  const contactInfoData: DropdownEntry[] = [
    {
      type: DROPDOWN_MENU_TYPES_ENUM.CONTACT,
      code: WpContinent.AFRIQUE,
      name: 'Afrique',
      parent: { code: HEADER_MENUS_ENUM.CONTACT_REFERENT, name: 'Contacter un conseiller' },
      phone: '01 56 81 38 30'
    },
    {
      type: DROPDOWN_MENU_TYPES_ENUM.CONTACT,
      code: WpContinent.AMERIQUE_NORD,
      name: 'Amérique du Nord',
      parent: { code: HEADER_MENUS_ENUM.CONTACT_REFERENT, name: 'Contacter un conseiller' },
      phone: '01 53 63 13 43'
    },
    {
      type: DROPDOWN_MENU_TYPES_ENUM.CONTACT,
      code: WpContinent.AMERIQUE_LATINE,
      name: 'Amérique latine',
      parent: { code: HEADER_MENUS_ENUM.CONTACT_REFERENT, name: 'Contacter un conseiller' },
      phone: '01 53 63 13 40'
    },
    {
      type: DROPDOWN_MENU_TYPES_ENUM.CONTACT,
      code: WpContinent.ASIE_SUD,
      name: 'Asie',
      parent: { code: HEADER_MENUS_ENUM.CONTACT_REFERENT, name: 'Contacter un conseiller' },
      phone: '01 40 51 95 15'
    },
    {
      type: DROPDOWN_MENU_TYPES_ENUM.CONTACT,
      code: WpContinent.INDE,
      name: 'Indes',
      parent: { code: HEADER_MENUS_ENUM.CONTACT_REFERENT, name: 'Contacter un conseiller' },
      phone: '01 56 81 38 38'
    },
    {
      type: DROPDOWN_MENU_TYPES_ENUM.CONTACT,
      code: WpContinent.EUROPE,
      name: 'Europe',
      parent: { code: HEADER_MENUS_ENUM.CONTACT_REFERENT, name: 'Contacter un conseiller' },
      phone: '01 56 81 38 30'
    },
    {
      type: DROPDOWN_MENU_TYPES_ENUM.CONTACT,
      code: WpContinent.ORIENT,
      name: 'Orient',
      parent: { code: HEADER_MENUS_ENUM.CONTACT_REFERENT, name: 'Contacter un conseiller' },
      phone: '01 56 81 38 30'
    },
    {
      type: DROPDOWN_MENU_TYPES_ENUM.CONTACT,
      code: WpContinent.OCEANIE,
      name: 'Océanie',
      parent: { code: HEADER_MENUS_ENUM.CONTACT_REFERENT, name: 'Contacter un conseiller' },
      phone: '01 40 51 95 00'
    },
    {
      type: DROPDOWN_MENU_TYPES_ENUM.CONTACT,
      code: WpContinent.ASIE_NORD,
      name: 'Chine',
      parent: { code: HEADER_MENUS_ENUM.CONTACT_REFERENT, name: 'Contacter un conseiller' },
      phone: '01 40 51 95 00'
    }
  ];

  const businessHeaderDataMenu = computed(() => [
    {
      title: 'Nos voyages',
      dropdown: {
        component: HEADER_MENUS_ENUM.TRAVELS,
        data: {
          items: businessHeader.travelsMenu?.map(travel => ({
            type: DROPDOWN_MENU_TYPES_ENUM.CATEGORY,
            image: travel.image?.data.attributes.url,
            name: travel.label,
            path: travel.link,
            parent: { code: HEADER_MENUS_ENUM.TRAVELS, name: 'Nos voyages' }
          }))
        }
      }
    },
    {
      title: 'Références & témoignages',
      dropdown: {
        component: HEADER_MENUS_ENUM.REFS_AND_TESTIMONIALS,
        data: {
          items: businessHeader.refsAndTestimonialsMenu?.map(refsAndTemItem => ({
            type: DROPDOWN_MENU_TYPES_ENUM.CATEGORY,
            image: refsAndTemItem.image?.data.attributes.url,
            name: refsAndTemItem.label,
            path: refsAndTemItem.link,
            parent: {
              code: HEADER_MENUS_ENUM.REFS_AND_TESTIMONIALS,
              name: 'Références & témoignages'
            }
          }))
        }
      }
    },
    {
      title: 'Qui sommes nous?',
      dropdown: {
        component: HEADER_MENUS_ENUM.WHO_ARE_WE,
        phrase: 'créateur des plus beaux <span class="highlight">voyages depuis 1991</span>',
        data: {
          items: businessHeader.whoAreWeMenu?.map(whoAreWeItem => ({
            type: DROPDOWN_MENU_TYPES_ENUM.CATEGORY,
            image: whoAreWeItem.image?.data.attributes.url,
            name: whoAreWeItem.label,
            path: whoAreWeItem.link,
            parent: { code: HEADER_MENUS_ENUM.WHO_ARE_WE, name: 'Qui sommes nous?' }
          }))
        }
      }
    }
  ]);

  const clientHeaderDataMenu = computed(() => [
    {
      title: 'Destinations',
      dropdown: {
        phrase: 'Ici commence <span class="highlight">l\'ailleurs</span>',
        component: HEADER_MENUS_ENUM.DESTINATION,
        data: destinations
      }
    },
    {
      title: 'Où et quand partir',
      dropdown: {
        phrase: '<span class="highlight">Quand</span> partir ?',
        component: HEADER_MENUS_ENUM.WHEN_WHERE_TO_GO,
        data: {
          items: header.whereWhenToGoMenu?.map(whereWhenToGoItem => ({
            color: whereWhenToGoItem.color,
            name: whereWhenToGoItem.label,
            path: `/${whereWhenToGoItem.link}`, // old => path: `${URLS_ENUM.WHEN_WHERE_TO_GO}/${whereWhenToGoItem.link}`,
            parent: {
              code: HEADER_MENUS_ENUM.WHEN_WHERE_TO_GO,
              name: 'Où et quand partir ?'
            }
          }))
        }
      }
    },
    {
      title: 'Inspirations',
      cssClass: 'full-screen-only',
      dropdown: {
        phrase: 'Le voyage selon <span class="highlight">vos envies</span>',
        component: HEADER_MENUS_ENUM.INSPIRATIONS,
        data: {
          items: header.inspirationMenu?.slice(0, 5).map(inspirationItem => ({
            type: DROPDOWN_MENU_TYPES_ENUM.CATEGORY,
            image: inspirationItem.image?.data.attributes.url,
            name: inspirationItem.label,
            path: inspirationItem.link,
            parent: { code: HEADER_MENUS_ENUM.INSPIRATIONS, name: 'Inspirations' }
          }))
        }
      }
    },
    {
      title: 'Qui sommes nous',
      dropdown: {
        phrase: 'créateur des plus beaux <span class="highlight">voyages depuis 1991</span>',
        component: HEADER_MENUS_ENUM.WHO_ARE_WE,
        data: {
          items: header.whoAreWeMenu?.slice(0, 5).map(whoAreWeItem => ({
            type: DROPDOWN_MENU_TYPES_ENUM.CATEGORY,
            image: whoAreWeItem.image?.data.attributes.url,
            name: whoAreWeItem.label,
            path: whoAreWeItem.link,
            parent: { code: HEADER_MENUS_ENUM.WHO_ARE_WE, name: 'Qui sommes nous?' }
          }))
        }
      }
    },
    {
      title: 'Culture & Voyage',
      cssClass: 'full-screen-only',
      dropdown: {
        phrase: 'Autour du <span class="highlight">voyage</span>',
        component: HEADER_MENUS_ENUM.CULTURE_AND_TRAVEL,
        data: {
          items: header.cultureAndTravelMenu?.map(item => ({
            type: DROPDOWN_MENU_TYPES_ENUM.CATEGORY,
            image: item.image?.data.attributes.url,
            name: item.label,
            path: item.link,
            parent: { code: HEADER_MENUS_ENUM.CULTURE_AND_TRAVEL, name: 'Culture et voyage' }
          }))
        }
      }
    },
    {
      title: 'Offres spéciales',
      dropdown: {
        component: HEADER_MENUS_ENUM.SPECIAL_OFFERS,
        // TODO: Ask to design which data to implement
        data: { items: [] }
      }
    },
    {
      title: 'Contacter un conseiller',
      dropdown: {
        component: HEADER_MENUS_ENUM.CONTACT_REFERENT,
        // TODO: Ask to design which data to implement
        data: { items: contactInfoData }
      }
    }
  ]);

  return {
    // @ts-expect-error
    menus: isBusinessHeader ? businessHeaderDataMenu.value : clientHeaderDataMenu.value,
    phone: '01 64 78 56 43'
  };
}
