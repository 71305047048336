<template>
  <div class="when-where-to-go">
    <NuxtLink
      class="guide-button"
      :href="`/${ROUTE_NAMES.WHEN_WHERE_TO_GO}`"
      @click="emit('item-click')"
    >
      Guidez-moi
    </NuxtLink>
    <div class="grid">
      <NuxtLink
        v-for="entry of data.items"
        :key="entry.path"
        :href="entry.path"
        class="item"
        @click="emit('item-click')"
      >
        <span class="name" :class="{ blue: entry.color === 'primary' }">{{ entry.name }}</span>
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ROUTE_NAMES from '@/lib/types/enums/routeNames.enum';
import { DropdownData } from '@/lib/types/models/header';

defineProps<{
  data: DropdownData;
}>();

const emit = defineEmits<(event: 'item-click') => void>();
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/button.scss';
@use '$/mouse-behavior.scss';

.when-where-to-go {
  position: relative;

  flex-direction: column;
  gap: 40px;

  width: 100%;
  height: 180px; // old: height: 200px;

  color: colors.$black;

  .guide-button {
    @include button.plain(colors.$green);

    position: absolute;
    top: 75%; // old: top: 80%;
    right: 50%;
    transform: translateX(50%);
  }

  .grid {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 14px 0; // old: gap: 16px 24px;

    .item {
      flex-direction: column;
      gap: 10px;

      .name {
        @include mouse-behavior.clickable-alpha($color: colors.$green, $alpha: 0.8);
      }

      .blue {
        @include mouse-behavior.clickable-alpha($color: colors.$green, $alpha: 0.8);

        color: colors.$green;
      }
    }
  }
}
</style>
