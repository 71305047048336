import { FooterModel } from '@/lib/types/models/footer';

export default function useFooter() {
  const footerData: FooterModel = {
    agencyInfos: {
      time: {
        start: 10,
        end: 19
      },
      emailAddress: 'info@lesmaisonsduvoyage.com',

      address: '76 rue Bonaparte, Paris 6ème'
    },

    continents: [
      {
        name: 'Afrique',
        phoneNumber: '01 56 81 38 29'
      },
      {
        name: 'Indes',
        phoneNumber: '01 56 81 38 38'
      },
      {
        name: 'Europe',
        phoneNumber: '01 56 81 38 30'
      },
      {
        name: 'Orient',
        phoneNumber: '01 56 81 38 30'
      },
      {
        name: 'Océanie',
        phoneNumber: '01 70 36 35 40'
      },
      {
        name: 'Amérique du Nord',
        phoneNumber: '01 53 63 13 43'
      },
      {
        name: 'Amérique latine',
        phoneNumber: '01 53 63 13 40'
      },
      {
        name: 'Asie',
        phoneNumber: '01 40 51 95 15'
      },
      {
        name: 'Chine',
        phoneNumber: '01 40 51 95 00'
      },
      {
        name: 'Maison de thé',
        phoneNumber: '01 50 51 95 17'
      }
    ],

    menuElements: [
      {
        title: 'Les Maisons du Voyage',
        elements: [
          {
            name: 'Qui sommes-nous ?',
            link: '/qui-sommes-nous'
          },
          {
            name: 'Notre valeur ajoutée',
            link: '/valeur-ajoutee'
          },
          {
            name: 'Notre engagement responsable',
            link: '/notre-engagement-responsable'
          },
          {
            name: 'Nous rejoindre',
            link: 'https://www.welcometothejungle.com/fr/companies/les-maisons-du-voyage'
          },
          {
            name: 'Revues de presse',
            link: '/revue-de-presse'
          },
          {
            name: 'Actualités',
            link: '/autour-du-voyage/actualite-voyageur'
          }
        ]
      },
      {
        title: 'Vous informer',
        elements: [
          {
            name: 'Votre agence de voyage à Paris',
            link: '/agence-de-voyage-paris-6eme-arrondissement'
          },
          {
            name: 'Votre voyage de  A à Z',
            link: '/circuit-groupe-de-a-a-z'
          },
          {
            name: 'Idées voyage',
            link: '/inspiration-voyage/idee-voyage'
          },
          {
            name: 'Voyages en groupe',
            link: 'https://www.maisonsduvoyage.com/voyages-groupe/'
          },
          {
            name: 'Écotourisme',
            link: '/inspiration-voyage/ecotourisme'
          },
          {
            name: 'Voyage sur mesure',
            link: '/inspiration-voyage/voyage-sur-mesure'
          }
        ]
      },
      {
        title: 'Infos légales',
        elements: [
          {
            name: 'Calculateur CO²',
            link: 'https://eco-calculateur.aviation-civile.gouv.fr/'
          },
          {
            name: 'Sécurité & confidentialité',
            link: '/politique-de-confidentialite'
          },
          {
            name: 'Assurances',
            link: '/assurances'
          },
          {
            name: 'Charte avis voyageur',
            link: 'https://www.maisonsduvoyage.com/documents/CharteAvisLMDV.pdf'
          },
          {
            name: 'Conditions générales de vente',
            link: '/conditions-de-vente' // old conditions-generales-de-vente
          }
        ]
      }
    ],

    menuLogosElements: [
      {
        title: 'Notre engagement responsable',
        elements: [
          {
            name: '/images/footer/atr.png',
            link: '/notre-engagement-responsable'
          }
        ]
      },
      {
        title: 'Assurances',
        elements: [
          {
            name: '/images/footer/mutuaide.png',
            link: '#'
          }
        ]
      },
      {
        title: 'Paiement sécurisé',
        elements: [
          {
            name: '/images/footer/visa.png',
            link: '#'
          },
          {
            name: '/images/footer/mastercard.png',
            link: '#'
          },
          {
            name: '/images/footer/american-express.png',
            link: '#'
          },
          {
            name: '/images/footer/3ds.png',
            link: '#'
          }
        ]
      },
      {
        title: 'Partenaires',
        elements: [
          {
            name: '/images/footer/iata.png',
            link: '#'
          },
          {
            name: '/images/footer/atradius.png',
            link: '#'
          },
          {
            name: '/images/footer/seto.png',
            link: '#'
          }
        ]
      }
    ]
  };

  return footerData;
}
